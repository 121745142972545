import {Component} from '@angular/core';
import {BaseModalComponent} from '@util/services/util-modal-types';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {KurzContactRequestModalComponent} from '../../kurz-contact-request-modal/kurz-contact-request-modal.component';
import {filter, take} from 'rxjs/operators';
import {UtilModalService} from '@util/services/util-modal.service';
import {TranslationService} from '@spartacus/core';
import {KurzProductService} from 'src/app/custom/services/kurz-product.service';

type CountrySelectorCode = 'de' | 'fr' | 'benelux' | 'uk' | 'ch' | 'nz' | 'au' | 'us';

export interface KurzCountrySelectorModalData {
  selectableCodes: CountrySelectorCode[];
  selected: CountrySelectorCode;
}

@Component({
  selector: 'app-kurz-country-selector-modal',
  templateUrl: './kurz-country-selector-modal.component.html',
  styleUrls: ['./kurz-country-selector-modal.component.scss']
})
export class KurzCountrySelectorModalComponent extends BaseModalComponent<KurzCountrySelectorModalData, CountrySelectorCode> {

  constructor(private utilModalService: UtilModalService, private translationService: TranslationService) {
    super();
  }


  static getCurrentCountryCode(): KurzCountrySelectorModalData['selected'] {
    let baseCode = window.location.pathname.split('/').find(part => part.startsWith('kurz-')) || 'kurz-de';
    baseCode = baseCode.slice('kurz-'.length);

    return (baseCode === 'nl' ? 'benelux' : baseCode) as KurzCountrySelectorModalData['selected'];
  }

  codes$ = new BehaviorSubject<CountrySelectorCode[]>(this.injectedModalData.selectableCodes);

  selected$ = new BehaviorSubject<CountrySelectorCode>(this.injectedModalData.selected);

  selectCountry(code: CountrySelectorCode) {
    this.closeModal(code);
  }

  openRequestModal() {
    this.closeModal();
    combineLatest([
      this.translationService.translate('pdp.orderForm.secondaryButtons.openRequestModal.subjectValue').pipe(filter(_ => !!_), take(1))
    ]).pipe(take(1)).subscribe(([subjectValue]) => {

      const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      };

      this.utilModalService.openCustomModal(KurzContactRequestModalComponent, {
        mailType: 'GENERAL_REQUEST',
        contextItems: [
          { type: 'textonlyInput', labelTranslationKey: 'contactModal.dateLabel', value: new Date().toLocaleDateString(void 0, options) },
          { type: 'textonlyInput', labelTranslationKey: 'contactModal.subjectLabel', value: subjectValue },
        ],
      }, { size: 'xl' });

    });

  }
}
