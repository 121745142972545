<app-kurz-modal-wrapper [options]="{ref: this}">

  <ng-template modalSlot="header">
    <h1 aria-live="polite">{{ 'customLogin.countries.title' | cxTranslate }}</h1>
  </ng-template>

  <ng-template modalSlot="content">

    <div class="modal-form">

      <ng-container *ngIf="codes$ | async as codes">
        <ng-container *ngFor="let code of codes">

          <div
            role="button"
            tabindex="0"
            [ngClass]="['selectable-country', (selected$ | async) === code ? 'selected' : 'unselected']"
            attr.aria-selected="{{(selected$ | async) === code}}"
            (activateElement)="selectCountry(code)"
          >
            <img [src]="'assets/images/countries/' + code + '.svg'" [alt]="code + ' flag'">
            <div>{{ ('customLogin.countries.' + code) | cxTranslate }}</div>
          </div>

        </ng-container>
      </ng-container>

    </div>
    <div class="contact">
      <app-button
      class="open-request-modal-button"
      [label]="'customLogin.contact' | cxTranslate"
      [colorTheme]="['SECONDARY', 'SECONDARY', {'colorThemeBorderColor': 'var(--color-theme-background-color)'}]"
      buttonSize="MEDIUM"
      borderLeftIconType="ENVELOPE"
      (activateElement)="openRequestModal()"
      flex
      ></app-button>
    </div>

  </ng-template>

  <!-- No Footer modal slot means that there will be footer element in the modal itself -->
  <!-- <ng-template modalSlot="footer"></ng-template> -->

</app-kurz-modal-wrapper>
