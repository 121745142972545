import { Component, Input, OnDestroy, inject } from '@angular/core';
import { AuthRedirectService, WindowRef } from '@spartacus/core';
import { UtilModalService } from '@util/services/util-modal.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { KurzCountrySelectorModalComponent, KurzCountrySelectorModalData } from './kurz-country-selector-modal/kurz-country-selector-modal.component';
import { coerceBoolean } from '@util/functions/objects';
import { kurzMachineRedirect } from 'src/app/custom/functions/kurz-redirect.functions';

@Component({
  selector: 'app-kurz-country-selector',
  templateUrl: './kurz-country-selector.component.html',
  styleUrls: ['./kurz-country-selector.component.scss']
})
export class KurzCountrySelectorComponent implements OnDestroy {

  private windowRef = inject(WindowRef);
  private utilModalService = inject(UtilModalService);
  private authRedirectService = inject(AuthRedirectService);

  private sub: Subscription;

  protected countryCode$ = new BehaviorSubject(this.getCurrentCountryCode());
  protected onlyExternalFlag = false;

  @Input()
  set externalTrigger(value: Observable<any>) {
    this.sub?.unsubscribe();
    this.sub = value.subscribe(val => {
      if (val) {
        this.openCountrySelector();
      }
    });
  }

  @Input()
  set onlyExternal(value: boolean) {
    this.onlyExternalFlag = coerceBoolean(value);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  openCountrySelector() {

    const selectedCode = this.getCurrentCountryCode();

    const data: KurzCountrySelectorModalData = {
      selectableCodes: ['de', 'fr', 'benelux', 'uk', 'ch', 'nz', 'au', 'us'],
      selected: selectedCode
    };

    this.utilModalService.openCustomModal(KurzCountrySelectorModalComponent, data, {
      closableByEscape: true,
      closeableWithBackdraftClick: true,
      size: 'md'
    }).waitForResultAfterDelay().subscribe(rawCode => {

      kurzMachineRedirect({
        countryCode: rawCode,
        authRedirectService: this.authRedirectService,
        windowRef: this.windowRef,
        siteContext: null // will be determined by the country code
      });
    });
  }

  private getCurrentCountryCode(): KurzCountrySelectorModalData['selected'] {
    let baseCode = this.windowRef.nativeWindow.location.pathname.split('/').find(part => part.startsWith('kurz-')) || 'kurz-de';
    baseCode = baseCode.slice('kurz-'.length);

    return (baseCode === 'nl' ? 'benelux' : baseCode) as KurzCountrySelectorModalData['selected'];
  }

}
